import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom'
import { Compose } from './Compose';
import { CreateMessage } from './CreateMessage';

function App() {
  return (
    <Switch>
      <Route exact path='/composesms' component={Compose}/>
      <Route exact path='/createMessage' component={CreateMessage}/>
      <Route path='/subscribeSMS'>
        <Redirect to={`sms://12345&body=Send%20this%20message%20to%20subscribe%20to%20text%20updates%20on%20voting%21%0A%0AReferral%20ID%20%3D%2012sdf4j`}/>
      </Route>
    </Switch>


  );
}

export default App;
