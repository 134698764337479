import { useEffect, useState } from "react";
import { isWindows, isAndroid, isIOS, isMacOs } from 'react-device-detect'
import { Container, Grid, Typography, Button } from '@material-ui/core';

// returns the URL for invoking the SMS composer for the current OS
function composeUrl(message, messageId, supporterId, referralDepth) {
  // The forwarding URL we embed in the message
  const location = window.location;
  const baseUrl = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
  const fwdUrl = new URL(`${baseUrl}/composesms`);
  fwdUrl.searchParams.append("msgID", messageId);
  fwdUrl.searchParams.append("supporterid", `${supporterId}${referralDepth}`);

  let urlPrefix;
  if (isIOS) {
    urlPrefix = "sms://&&body=";
  } else if (isAndroid) {
    urlPrefix = "sms:?&body=";
  } else if (isMacOs) {
    urlPrefix = "sms://&&body=";
  } else if (isWindows) {
    urlPrefix = "sms://&&body=";
  }

  // TODO: why do we end up with encoded linebreaks that we have to decode?
  const decodedMessage = decodeURIComponent(message);
  const encodedMessage = encodeURIComponent(`${decodedMessage} ${fwdUrl.toString()}`);
  return `${urlPrefix}${encodedMessage}`;
}

// Open the native SMS composer on the device
async function openNativeComposer(message, messageId, referralId, referralDepth){
  const supporterId = generateId();
  window.location = composeUrl(message, messageId, supporterId, referralDepth);
  writeReferralData(messageId, referralId, supporterId, referralDepth);

}

async function getMessage(id) {
  const requestInfo = {
    crossDomain: true,
    headers: {
      "content-type": "application/json",
      "x-apikey": process.env.REACT_APP_RESTDB_API_KEY,
      "cache-control": "no-cache"
    }
  }

  const result = await fetch(`${process.env.REACT_APP_RESTDB_URL}/rest/messages`, requestInfo);
  const messages = await result.json();
  for (var i = 0; i < messages.length; i++) {
    if (messages[i].msgid === id) {
      return messages[i]["message"];
    }
  }

  return null;
}

// Format is "XXXX123"
// TODO: 4 chars is too few for the supporter id and we should use a different param or separator
// to not depend on length
function getReferralDepth(referralId) {
  if (referralId && referralId.length > 4 && Number.isFinite(parseInt(referralId.slice(4)))) {
    return parseInt(referralId.slice(4));
  } else {
    return 0;
  }
}

async function writeReferralData(messageId, referralId, supporterId, networkOrder){
  const locationData = await getLocationData();

  var jsondata = {
        msgid: messageId,
        "referral-id": referralId,
        "timestamp": Date.now(),
        "city": locationData.city,
        "state": locationData.region,
        "ip": locationData.ip,
        "isiPhone": isIOS,
        "isAndroid": isAndroid,
        "isMac": isMacOs,
        "isWindows": isWindows,
        "supporterhash": supporterId,
        "network-order": networkOrder
    };
    var settings = {
        crossDomain: true,
        method: "POST",
        headers: {
            "content-type": "application/json",
            "x-apikey": process.env.REACT_APP_RESTDB_API_KEY,
            "cache-control": "no-cache"
        },
        body: JSON.stringify(jsondata)
    }
    return fetch(`${process.env.REACT_APP_RESTDB_URL}/rest/sendlog`, settings);
}

async function getLocationData() {
  const response = await fetch(process.env.REACT_APP_GEOIP_URL);
  return response.json();
}

function generateId(length=4){
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let result = '';
  for (var i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}

export function Compose() {
  const [missingId, setMissingId] = useState(false);

  useEffect(() => {
    async function onLoad() {
      const params = (new URL(window.location)).searchParams;
      const msgId = params.get('msgID');
      if (!msgId) {
        setMissingId(true);
        return;
      }
      const referralId = params.get('supporterid')
      const message = await getMessage(msgId);
      if (!message) {
        setMissingId(true);
        return;
      }
      const referralDepth = getReferralDepth(referralId);
      openNativeComposer(message, msgId, referralId, referralDepth+1)
    };
    onLoad();
  })

  if (missingId) {
    return <>Error: Missing or incorrect ID. Please try again.</>;
  }

  return (
    <Grid container justify="center" direction="column" style={{ minHeight: '100vh' }}>
      <Container maxWidth="sm">
        <Typography variant="h4" gutterBottom>
        Loading your device's messaging app.
        </Typography>
        <Typography variant="h5" gutterBottom>
        If you are not redirected within a few seconds, click the button below.
        </Typography>
        <Button variant="contained" color="primary" fullWidth onClick={openNativeComposer}>Create message</Button>
      </Container>
    </Grid>
  );




}