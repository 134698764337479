import {Container, Grid, Button, TextField} from '@material-ui/core';
import {useState} from 'react';

export function CreateMessage() {
  const [message, setMessage] = useState();
  const [messageId, setMessageId] = useState();
  const [created, setCreated] = useState();

  const  handleCreateMessage = async () => {
    const response = await fetch(`${process.env.REACT_APP_RESTDB_URL}/rest/messages`, {
      method: "POST",
      body:JSON.stringify({message, msgid: messageId}),
      crossDomain: true,
      headers: {
        "x-apikey": process.env.REACT_APP_RESTDB_API_KEY,
        "content-type": "application/json"
      }});
    const responseJSON = await response.json();
    if (responseJSON['_id']) {
      setCreated(true);
    }

  };

  return (
    <Grid container justify="center" direction="column" style={{ minHeight: '100vh' }}>
        <Container maxWidth="sm">
          <form id="messages-form">
            <TextField label="Message"
            multiline
            rows="4"
            variant="outlined"
            fullWidth onChange={(e)=>{setMessage(e.target.value)}} />

            <TextField label="Message ID" fullWidth  onChange={(e)=>{setMessageId(e.target.value)}}/>
            <br/><br/>
            {created ? <> Thank you! </> :
              <Button variant="contained" color="primary" fullWidth onClick={handleCreateMessage}>Create</Button> }
          </form>
        </Container>
    </Grid>

  )
}
